module.exports = global.config = {
  i18n: {
      welcome: {
          en: "Welcome",
          fa: "خوش آمدید"
      }
      // rest of your translation object
  },

  urlBase: "https://zurco.com.mx:8085/validador"

  // other global config variables you wish
};